import { useCallback, useEffect, useMemo, useState } from "react";
import { v4 as uuid } from "uuid";
import {
  atom,
  useRecoilValue,
  useSetRecoilState,
} from "@tbml/shared-dependencies/recoil";

export type AppBarStatusState = "idle" | "saving" | "saved";
export type AppBarStatus = { id: string; state: AppBarStatusState };

const statusAtom = atom<AppBarStatus[]>({
  key: "appBar/status",
  default: [],
});

type UseStatusValue = () => {
  statusValues: AppBarStatus[];
  status: AppBarStatusState;
};

export const useStatusValue: UseStatusValue = () => {
  const statusValues = useRecoilValue(statusAtom);

  const isSaving = useMemo(
    () => statusValues.some((statusValue) => statusValue.state === "saving"),
    [statusValues]
  );

  const isSaved = useMemo(
    () =>
      statusValues.some((statusValue) => statusValue.state === "saved") &&
      statusValues.every((statusValue) => statusValue.state !== "saving"),
    [statusValues]
  );

  const getAppStatus = () => {
    if (isSaving) return "saving";
    if (isSaved) return "saved";

    return "idle";
  };

  return { statusValues, status: getAppStatus() };
};

export type UseSetStatus = (options?: UseStatusOptions) => {
  setStatus: (status: AppBarStatusState) => void;
  removeStatus: () => void;
};

export type UseStatusOptions = {
  persistAfterUnmount?: boolean;
};

export const useSetStatus: UseSetStatus = ({ persistAfterUnmount } = {}) => {
  const setStatusValues = useSetRecoilState(statusAtom);
  const [id] = useState(uuid());

  const setStatus = useCallback(
    (state: AppBarStatusState) =>
      setStatusValues((prevStatusValues) => {
        const filteredStatusValues = prevStatusValues.filter(
          (statusValue) => statusValue.id !== id
        );

        return state === "idle"
          ? filteredStatusValues
          : [...filteredStatusValues, { id, state }];
      }),
    [id, setStatusValues]
  );

  const removeStatus = useCallback(() => {
    setStatusValues((prevStatusValues) =>
      prevStatusValues.filter((statusValue) => statusValue.id !== id)
    );
  }, [id, setStatusValues]);

  useEffect(
    () => () => {
      if (!persistAfterUnmount) {
        removeStatus();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return { setStatus, removeStatus };
};

type UseStatus = (
  options?: UseStatusOptions
) => ReturnType<UseStatusValue> & ReturnType<UseSetStatus>;
export const useStatus: UseStatus = (options) => ({
  ...useStatusValue(),
  ...useSetStatus(options),
});
