import * as React from "react";
import {
  IconButton,
  Breadcrumbs as MuiBreadcrumbs,
  Tooltip,
} from "@mui/material";
import { v4 as uuid } from "uuid";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { useBreadcrumbs } from "@tbml/hooks/useBreadcrumbs";
import { useRouter } from "@tbml/shared-dependencies/router";
import { Link } from "../Link";
import { Spacer } from "../Spacer";
import { Text } from "../Typography";
import { BreadcrumbsWrapper, InvertedLink } from "./styles";

export type Breadcrumb = {
  label: string;
  href: string | null;
};

type TextRenderProps = {
  label: string;
  isTooltip?: boolean;
  isColor?: boolean;
};

export type Props = {
  breadcrumbs: Breadcrumb[];
};

const getUpLink = (previousHref: string, from?: string) =>
  from ? previousHref.concat(`#${from}`) : previousHref;

function UpArrow({ breadcrumbs }: Props): JSX.Element | null {
  const { query: routerQuery } = useRouter();
  if (breadcrumbs.length < 2) return null;
  const { href: previousHref } = breadcrumbs[breadcrumbs.length - 2];
  return (
    <>
      {previousHref ? (
        <Link
          href={getUpLink(
            previousHref,
            typeof routerQuery.from === "string" ? routerQuery.from : ""
          )}
          aria-label="Navigate up"
          animation="slideRight"
        >
          <IconButton
            edge="start"
            size="large"
            aria-label="back"
            color="primary"
          >
            <ArrowBackIcon fontSize="inherit" />
          </IconButton>
        </Link>
      ) : (
        <IconButton edge="start" size="large" aria-label="back" color="primary">
          <ArrowBackIcon fontSize="inherit" />
        </IconButton>
      )}
      <Spacer size="gutterS" />
    </>
  );
}

function TextRender({
  label = "",
  isTooltip = false,
  isColor = false,
}: TextRenderProps): JSX.Element {
  return isTooltip && label.length > 27 ? (
    <Tooltip title={`${label}`} placement="top">
      <div>
        <Text color={isColor ? "inherit" : ""}>
          {label?.substring(0, 20)}...
        </Text>
      </div>
    </Tooltip>
  ) : (
    <Text color={isColor ? "inherit" : ""}>{label}</Text>
  );
}
export function Breadcrumbs(): JSX.Element {
  const [breadcrumbs] = useBreadcrumbs();

  return (
    <BreadcrumbsWrapper>
      <UpArrow breadcrumbs={breadcrumbs} />
      <MuiBreadcrumbs aria-label="breadcrumb">
        {breadcrumbs.map(({ label, href, allowTooltip }, index) => {
          if (href === null) {
            return (
              <TextRender key={uuid()} label={label} isTooltip={allowTooltip} />
            );
          }
          if (index === breadcrumbs.length - 1) {
            return (
              <TextRender key={href} label={label} isTooltip={allowTooltip} />
            );
          }
          return (
            <InvertedLink href={href} key={href} animation="slideRight">
              <TextRender isColor label={label} isTooltip={allowTooltip} />
            </InvertedLink>
          );
        })}
      </MuiBreadcrumbs>
    </BreadcrumbsWrapper>
  );
}
