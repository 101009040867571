import { styled, Toolbar, CircularProgress } from "@mui/material";
import { CloudDoneOutlined, Save as SaveIcon } from "@mui/icons-material";
import tokens from "@tbml/tokens";
import { PAGE_LAYOUT_PADDING } from "@tbml/components/PageLayout";

export const StyledToolbar = styled(Toolbar)`
  background: ${tokens.color.backgroundDefault.value};
  display: grid;
  grid-template-columns: calc(100% - ${PAGE_LAYOUT_PADDING}) ${PAGE_LAYOUT_PADDING};
  justify-items: center;
  align-items: center;
  padding-left: ${tokens.spacing.gutterM.value};
`;

export const Grow = styled("div")`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SavingProgress = styled(CircularProgress)`
  color: ${tokens.color.successLight.value};
  position: absolute;
  top: -6px;
  left: -6px;
  z-index: 1;
`;

export const SavingIcon = styled(SaveIcon)`
  color: ${tokens.color.successLight.value};
`;

export const SaveSuccessIcon = styled(CloudDoneOutlined)`
  color: ${tokens.color.successDark.value};
`;

export const Relative = styled("div")`
  position: relative;
  display: flex;
  align-items: center;
`;
