import React, { useEffect, useMemo, useState } from "react";
import * as Sentry from "@sentry/react";
import type { AppProps } from "next/app";
import Head from "next/head";
import getConfig from "next/config";
import Script from "next/script";
import { EmotionCache } from "@emotion/cache";
import {
  HydrationBoundary,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { UseApiContext, UseApiResult } from "@tbml/hooks/useApi";
import { RecoilRoot } from "@tbml/shared-dependencies/recoil";
import { TbmlStyling } from "@tbml/components/Theme";
import { Spacer } from "@tbml/components/Spacer";
import { PageLayout } from "@tbml/components/PageLayout";
import { SnackbarProvider } from "@tbml/shared-dependencies/notistack";
import { Splashscreen } from "@tbml/components/Splashscreen";
import { ImageModalContainer } from "@tbml/components/ImageModal/ImageModalContainer";
import { AppBar } from "../components/AppBar";
import { createEmotionCache } from "../lib/createEmotionCache";

const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: 10000 } },
});

const {
  publicRuntimeConfig: { apiUrl },
} = getConfig();

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

function Hydrate({
  dehydratedState,
}: {
  dehydratedState: unknown;
}): JSX.Element | null {
  const [isClientRender, setIsClientRender] = useState(false);

  // make sure first client render matches the server render
  useEffect(() => {
    if (isClientRender) return;
    setIsClientRender(true);
  }, [isClientRender]);

  if (!isClientRender) {
    return null;
  }
  return <HydrationBoundary state={dehydratedState} />;
}

function Application({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps,
}: AppProps<{ dehydratedState: unknown }> & {
  emotionCache: EmotionCache;
}): JSX.Element {
  const useApiContextValue: Omit<UseApiResult, "fetch" | "token" | "client"> =
    useMemo(() => ({ url: apiUrl, provider: "fusionauth" }), []);

  return (
    <PageLayout>
      <Head>
        <link rel="icon" href="/favicon.ico" />
        <link rel="dns-prefetch" href="https://fonts.googleapis.com/" />
        <link rel="dns-prefetch" href={apiUrl} />

        {/* <!-- HTML Meta Tags --> */}
        <title>ENB Creator</title>
        <meta
          name="description"
          content="Content Management Platform for Executive News Briefings"
        />

        {/* <!-- Facebook Meta Tags --> */}
        <meta
          property="og:url"
          content="https://briefings-creator.unicepta.app/"
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Briefings Creator" />
        <meta property="og:description" content="Executive News Briefings" />
        <meta property="og:image" content="/images/metadata-image.png" />
        <meta property="og:image:width" content="1050" />
        <meta property="og:image:height" content="450" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:domain"
          content="briefings-creator.unicepta.app"
        />
        <meta
          property="twitter:url"
          content="https://briefings-creator.unicepta.app/"
        />
        <meta name="twitter:title" content="Briefings Creator" />
        <meta name="twitter:description" content="Executive News Briefings" />
        <meta name="twitter:image" content="/images/metadata-image.png" />

        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
      </Head>
      <Script
        strategy="lazyOnload"
        id="init_hotjar"
        dangerouslySetInnerHTML={{
          __html: `
            (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:3095722,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
          `,
        }}
      />
      <TbmlStyling cache={emotionCache}>
        <QueryClientProvider client={queryClient}>
          <Hydrate dehydratedState={pageProps.dehydratedState} />
          <RecoilRoot>
            <UseApiContext.Provider value={useApiContextValue}>
              <SnackbarProvider maxSnack={3}>
                <Splashscreen>
                  <AppBar />
                  <Spacer />
                  <Component {...pageProps} />
                  <ImageModalContainer />
                </Splashscreen>
              </SnackbarProvider>
            </UseApiContext.Provider>
          </RecoilRoot>
        </QueryClientProvider>
      </TbmlStyling>
    </PageLayout>
  );
}
// noinspection JSUnusedGlobalSymbols
export default Sentry.withProfiler(Application);
