import * as React from "react";
import { Tooltip, Button } from "@mui/material";
import { Breadcrumb, Breadcrumbs } from "@tbml/components/Breadcrumbs";
import { useBreadcrumbs } from "@tbml/hooks/useBreadcrumbs";
import { Link } from "@tbml/components/Link";
import { useRouter } from "@tbml/shared-dependencies/router";
import { useStatusValue } from "./useStatus";
import {
  Grow,
  Relative,
  SaveSuccessIcon,
  SavingIcon,
  SavingProgress,
  StyledToolbar,
} from "./styles";

export * from "./useStatus";

function Status(): JSX.Element {
  const { status } = useStatusValue();

  if (status === "saving") {
    return (
      <Tooltip title="Application is saving content...">
        <Relative>
          <SavingIcon aria-label="Application is saving content..." />
          <SavingProgress size={36} />
        </Relative>
      </Tooltip>
    );
  }

  if (status === "saved") {
    return (
      <Tooltip title="Application saved content successfully.">
        <SaveSuccessIcon aria-label="Application saved content successfully." />
      </Tooltip>
    );
  }

  return <div aria-label="idle" />;
}

export type DoneButtonProps = {
  breadcrumbs: Breadcrumb[];
};

function DoneButton({ breadcrumbs }: DoneButtonProps): JSX.Element | null {
  if (breadcrumbs.length < 2) return null;
  const { href: upLink } = breadcrumbs[breadcrumbs.length - 2];

  if (!upLink) return null;

  return (
    <Link href={upLink}>
      <Button variant="contained">Done</Button>
    </Link>
  );
}

export function AppBar(): JSX.Element {
  const [breadcrumbs] = useBreadcrumbs();
  const router = useRouter();
  const { storyId, articleId } = router.query;

  return (
    <StyledToolbar color="secondary" disableGutters>
      <Grow>
        {breadcrumbs.length >= 1 && <Breadcrumbs />}
        {storyId && !articleId && <DoneButton breadcrumbs={breadcrumbs} />}
      </Grow>
      <Status />
    </StyledToolbar>
  );
}
