import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import { gql } from "graphql-request";
import { useRouter } from "@tbml/shared-dependencies/router";
import { atom, useRecoilState } from "@tbml/shared-dependencies/recoil";
import { useCustomers } from "../useCustomers";
import { useIssues } from "../useIssues";
import { useStories } from "../useStories";
import { useArticles } from "../useArticles";

export type Breadcrumb = {
  label: string;
  href: string | null;
  isTooltip?: boolean;
  allowTooltip?: boolean;
};

const isValidQueryParam = (queryParam: unknown): queryParam is string =>
  queryParam !== undefined &&
  !(queryParam instanceof Array) &&
  typeof queryParam === "string";

const breadcrumbAtom = atom<Breadcrumb[] | null>({
  key: "hooks/breadcrumbs",
  default: null,
});

const getArticleBredcrumb = ({
  sortSectionName,
  topicName,
}: {
  sortSectionName?: string | null;
  topicName?: string;
}) => {
  if (topicName) {
    return `Article in ${topicName}`;
  }
  if (sortSectionName && sortSectionName !== "Unassigned") {
    return `Article in ${sortSectionName}`;
  }
  return "Article";
};

export const useBreadcrumbs = (): [
  Breadcrumb[],
  (newBreadcrumbs: Breadcrumb[] | null) => void
] => {
  const router = useRouter();
  const [breadcrumbs, setRecoilBreadcrumbs] = useRecoilState<
    Breadcrumb[] | null
  >(breadcrumbAtom);
  const { customerId, issueId, storyId, articleId } = router.query;
  const routerPath = router.route.includes("/[customerId]");

  let customerIdString = "";
  let issueIdString = "";
  let storyIdString = "";
  let articleIdString = "";

  if (isValidQueryParam(customerId)) {
    customerIdString = customerId;
  }
  if (isValidQueryParam(issueId)) {
    issueIdString = issueId;
  }
  if (isValidQueryParam(storyId)) {
    storyIdString = storyId;
  }
  if (isValidQueryParam(articleId)) {
    articleIdString = articleId;
  }

  const CustomerFragment = {
    fragmentName: "CustomerName",
    fragment: gql`
      fragment CustomerName on Customer {
        id
        name
      }
    `,
  };

  const IssueFragment = {
    fragmentName: "IssueTitle",
    fragment: gql`
      fragment IssueTitle on Issue {
        id
        title
        refDate
      }
    `,
  };

  const StoryFragment = {
    fragmentName: "StoryTitle",
    fragment: gql`
      fragment StoryTitle on Story {
        executiveBriefingTopic {
          title
        }
        id
        title
      }
    `,
  };

  const ArticleFragment = {
    fragmentName: "ArticleTitle",
    fragment: gql`
      fragment ArticleTitle on Article {
        id
        sortSection {
          name
        }
      }
    `,
  };
  const { data: customerData } = useCustomers(CustomerFragment).query({
    enabled: !!customerIdString,
    filter: { ids: [customerIdString] },
  });

  const { data: issueData } = useIssues(IssueFragment).query({
    enabled: !!issueIdString,
    filter: { ids: [issueIdString] },
  });

  const { data: storyData } = useStories(StoryFragment).query({
    enabled: !!storyIdString,
    filter: { ids: [storyIdString] },
  });

  const { data: articleData } = useArticles(ArticleFragment).query({
    enabled: !!articleIdString,
    filter: { ids: [articleIdString] },
  });

  const storyHref = () => {
    if (customerIdString && issueIdString && storyIdString && articleIdString) {
      return `/${customerIdString}/issue/${issueIdString}/story/${storyIdString}`;
    }
    if (issueIdString && articleIdString && storyIdString) {
      return `/${issueIdString}/stories/${storyIdString}`;
    }
    if (storyIdString && issueIdString) {
      return `/${issueIdString}`;
    }
    if (articleIdString && storyIdString) {
      return `/stories/${storyIdString}`;
    }
    return null;
  };

  if (breadcrumbs) return [breadcrumbs, setRecoilBreadcrumbs];

  return [
    [
      {
        label: "Start",
        href: issueIdString && !customerIdString ? `/${issueIdString}/` : "/",
      },
      ...(isValidQueryParam(customerId) && routerPath
        ? [
            {
              label: `${customerData?.[0]?.name ?? ""}`,
              href: issueIdString ? `/${customerIdString}/` : null,
            },
          ]
        : []),
      ...(isValidQueryParam(issueId) && customerIdString && routerPath
        ? [
            {
              label: `Issue: ${
                issueData?.issues[0]?.title ||
                (issueData?.issues[0]
                  ? format(
                      parseISO(issueData?.issues[0].refDate),
                      "dd/MM/yyyy, HH:MM"
                    )
                  : "")
              }`,
              href:
                storyIdString || articleIdString
                  ? `/${customerIdString}/issue/${issueIdString}`
                  : null,
            },
          ]
        : []),
      ...(isValidQueryParam(storyId)
        ? [
            {
              label: `Story: ${storyData?.[0]?.title ?? ""}`,
              href: storyHref(),
              allowTooltip: true,
            },
          ]
        : []),
      ...(isValidQueryParam(articleId)
        ? [
            {
              label: getArticleBredcrumb({
                sortSectionName: articleData?.[0]?.sortSection?.name,
                topicName: storyData?.[0]?.executiveBriefingTopic?.title,
              }),
              href: null,
            },
          ]
        : []),
    ],
    setRecoilBreadcrumbs,
  ];
};
