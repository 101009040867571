/* eslint-disable @20minutes/graphql/template-strings */
import { gql } from "graphql-request";
import {
  QueryObserverResult,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  Story,
  StoryFilter,
  StoryInput,
  ExecutiveBriefingTopic,
  StoryUpdateInput,
  Article,
  IssueOutput,
} from "@tbml/api-interface/graphql";
import {
  isLicenseError,
  isLicenseFreeError,
} from "@tbml/shared-dependencies/license";
import { useApi } from "../useApi";
import { Issue } from "../useIssues";
import { UseStoriesFieldsFragment } from "./fields";

export type UseStoryResult = {
  query: (args?: {
    filter?: StoryFilter;
    enabled?: boolean;
  }) => QueryObserverResult<Story[], Error>;
  mutator: UseMutationResult<Story, Error, StoryInput>;
  updater: UseMutationResult<Story[], Error, StoryUpdateInput>;
  deleter: UseMutationResult<void, Error, StoryFilter>;
};

const updateTopicStories = (
  cachedStories: ExecutiveBriefingTopic["stories"],
  newStory: Story
): ExecutiveBriefingTopic["stories"] => {
  let found = false;
  const { executiveBriefingTopic, __typename, ...storyInTopic } = newStory;
  return [
    ...(cachedStories
      ? cachedStories.map((cachedStory) => {
          if (cachedStory.id === storyInTopic.id) {
            found = true;
            return storyInTopic;
          }

          return cachedStory;
        })
      : []),
    ...(found ? [] : [storyInTopic]),
  ];
};

const updateIssueTopics = (
  cachedTopics: Issue["executiveBriefingTopics"],
  newStory: Story
): Issue["executiveBriefingTopics"] =>
  cachedTopics
    ? cachedTopics.map((cachedTopic) => ({
        ...cachedTopic,
        stories:
          cachedTopic.id === newStory.executiveBriefingTopicId
            ? updateTopicStories(cachedTopic.stories, newStory)
            : cachedTopic.stories,
      }))
    : [];

const isArticle = (
  maybeArticle: Article | undefined
): maybeArticle is Article => !!maybeArticle;

export const useStories = (
  {
    fragmentName = "UseStoriesFields",
    fragment = UseStoriesFieldsFragment,
  }: {
    fragmentName: string;
    fragment: string;
  } = {
    fragmentName: "UseStoriesFields",
    fragment: UseStoriesFieldsFragment,
  }
): UseStoryResult => {
  const { client, token } = useApi();
  const queryClient = useQueryClient();

  const useStoriesQuery = ({
    filter = {},
    enabled = true,
  }: {
    filter?: StoryFilter;
    enabled?: boolean;
  } = {}) =>
    useQuery<Story[], Error>({
      queryKey: ["stories", filter, fragmentName],
      queryFn: async () => {
        const {
          getStories: { stories },
        } = await client.request<{ getStories: { stories: Story[] } }>(
          gql`
              query GetStories($filter: StoryFilter!) {
                getStories(filter: $filter) {
                  stories {
                    ...${fragmentName}
                  }
                }
              }
              ${fragment}
            `,
          { filter }
        );
        if (stories) {
          stories.forEach((story: Story) => {
            queryClient.setQueryData(["story", story.id, fragmentName], story);

            if (story.executiveBriefingTopicId) {
              queryClient.setQueryData(
                [
                  "executiveBriefingTopic",
                  story.executiveBriefingTopicId,
                  fragmentName,
                ],
                story.executiveBriefingTopic
              );
            }
          });
        }
        return stories;
      },
      enabled: enabled && !!token,
      retry: (failureCount, error) => {
        if (isLicenseFreeError(error)) return false;
        if (isLicenseError(error)) return false;
        return failureCount !== 3;
      },
      placeholderData: () => {
        if (!filter?.ids) return undefined;

        const initialData: Story[] = [];
        const cachedStories = filter.ids.reduce(
          (acc: Story[], filterId: string) => {
            const cachedStory = queryClient.getQueryData<Story>([
              "story",
              filterId,
              fragmentName,
            ]);

            return !cachedStory ? acc : [...acc, cachedStory];
          },
          initialData
        );

        if (cachedStories === initialData) return undefined;
        return cachedStories;
      },
    });

  const mutator = useMutation<Story, Error, StoryInput>({
    mutationFn: async (input) => {
      const {
        mutateStory: {
          stories: [story],
        },
      } = await client.request<{ mutateStory: { stories: Story[] } }>(
        gql`
          mutation MutateStory($input: StoryInput!) {
            mutateStory(input: $input) {
              stories {
                ...${fragmentName}
              }
            }
          }
          ${fragment}
        `,
        { input }
      );
      return story;
    },
    onMutate: ({
      articleIds,
      executiveBriefingTopicId = null,
      issueId = null,
      imageFile,
      actionButtonIncluded,
      actionButtonName,
      actionButtonLink,
      ...storyInput
    }) => {
      const oldStory = queryClient.getQueryData<Story>([
        "story",
        storyInput.id,
        fragmentName,
      ]);
      const articles = articleIds
        .map((articleId) =>
          queryClient.getQueryData<Article>(["article", articleId])
        )
        .filter(isArticle);
      const executiveBriefingTopic =
        queryClient.getQueryData<ExecutiveBriefingTopic>([
          "executiveBriefingTopic",
          executiveBriefingTopicId,
        ]);

      const optimisticStory: Story = {
        ...storyInput,
        executiveBriefingTopicId,
        executiveBriefingTopic: executiveBriefingTopic
          ? {
              id: executiveBriefingTopic.id,
              title: executiveBriefingTopic.title,
              subTitle: executiveBriefingTopic.subTitle,
              customerId: executiveBriefingTopic.customerId,
              isDeleted: executiveBriefingTopic.isDeleted,
              deletedAt: executiveBriefingTopic.deletedAt,
            }
          : null,
        issueId,
        image: null,
        articleOrder: [],
        broadcastData: oldStory?.broadcastData ?? null,
        actionButtonIncluded: actionButtonIncluded ?? false,
        actionButtonName: actionButtonName ?? "",
        actionButtonLink: actionButtonLink ?? "",
        articles,
        assets: [],
        allAssets: [],
      };

      queryClient.setQueryData<Story>(
        ["story", storyInput.id, fragmentName],
        optimisticStory
      );

      if (
        optimisticStory.issueId &&
        optimisticStory.executiveBriefingTopicId &&
        (!oldStory ||
          oldStory?.issueId !== optimisticStory.issueId ||
          optimisticStory.executiveBriefingTopicId !==
            oldStory?.executiveBriefingTopicId)
      ) {
        const issuesCache = queryClient.getQueriesData<IssueOutput>({
          queryKey: ["issues"],
        });
        if (!issuesCache) return;
        issuesCache.forEach(([key, issueOutput]) => {
          if (!issueOutput) return;
          const issueToAddStory = issueOutput.issues.find(
            (issue) =>
              !!issue.executiveBriefingTopics &&
              issue.id === optimisticStory.issueId
          );
          if (issueToAddStory) {
            queryClient.setQueryData<IssueOutput>(key, () => ({
              ...issueOutput,
              issues: issueOutput.issues.map((issue) =>
                issue.id === issueToAddStory.id
                  ? {
                      ...issueToAddStory,
                      executiveBriefingTopics: updateIssueTopics(
                        issueToAddStory.executiveBriefingTopics,
                        optimisticStory
                      ),
                    }
                  : issue
              ),
            }));
          }
          if (!oldStory) return;
          const issueToRemoveStory = issueOutput.issues.find(
            (issue) =>
              !!issue.executiveBriefingTopics && issue.id === oldStory.issueId
          );
          if (issueToRemoveStory) {
            queryClient.setQueryData<IssueOutput>(key, () => ({
              ...issueOutput,
              issues: issueOutput.issues.map((issue) =>
                issue.id === issueToRemoveStory.id
                  ? {
                      ...issueToRemoveStory,
                      executiveBriefingTopics: (
                        issueToRemoveStory.executiveBriefingTopics ?? []
                      ).map((topic) => ({
                        ...topic,
                        stories: (topic.stories ?? []).filter(
                          (story) => story.id !== optimisticStory.id
                        ),
                      })),
                    }
                  : issue
              ),
            }));
          }
        });
      }
    },
    onSuccess: (story) => {
      queryClient.invalidateQueries({ queryKey: ["stories"] });
      queryClient.invalidateQueries({ queryKey: ["issues"] });
      queryClient.setQueryData<Story>(
        ["story", story.id, fragmentName],
        () => story
      );
    },
  });

  const updater = useMutation<Story[], Error, StoryUpdateInput>({
    mutationFn: async ({ filter, set }) => {
      const {
        updateStories: { stories },
      } = await client.request<
        { updateStories: { stories: Story[] } },
        StoryUpdateInput
      >(
        gql`
          mutation UpdateStories(
            $set: StoryUpdateFields!
            $filter: StoryFilter!
          ) {
            updateStories(set: $set, filter: $filter) {
              stories {
                ...${fragmentName}
              }
            }
          }
          ${fragment}
        `,
        { filter, set }
      );
      return stories;
    },
    onMutate: ({ set, filter }) => {
      const cachedStories = queryClient.getQueriesData<Story>({
        queryKey: ["story"],
      });

      cachedStories.forEach(([key, story]) => {
        if (!story) return;
        if (key[2] !== fragmentName) return;
        if (filter.ids && !filter.ids.includes(story.id)) return;
        if (filter.issueId && filter.issueId !== story.issueId) return;
        if (
          filter.executiveBriefingTopicId &&
          filter.executiveBriefingTopicId !== story.executiveBriefingTopicId
        )
          return;

        // Reorder articles
        const { orderedArticles, unorderedArticles } = (
          set.articleIds ?? []
        ).reduce<{
          orderedArticles: Article[];
          unorderedArticles: Article[];
        }>(
          (acc, storyId) => {
            const matchingStory = acc.unorderedArticles.find(
              (topicStory) => topicStory.id === storyId
            );
            if (!matchingStory) return acc;

            return {
              orderedArticles: [...acc.orderedArticles, matchingStory],
              unorderedArticles: acc.unorderedArticles.filter(
                (s) => s.id !== storyId
              ),
            };
          },
          { orderedArticles: [], unorderedArticles: story.articles ?? [] }
        );

        queryClient.setQueryData(key, {
          ...story,
          ...set,
          articles: [...orderedArticles, ...unorderedArticles],
        });
      });

      const issuesCache = queryClient.getQueriesData<IssueOutput>({
        queryKey: ["issues"],
      });
      if (!issuesCache) return;

      issuesCache.forEach(([key, issueOutput]) => {
        if (!issueOutput) return;
        queryClient.setQueryData<IssueOutput>(key, () => ({
          ...issueOutput,
          issues: issueOutput.issues.map((issue) => ({
            ...issue,
            executiveBriefingTopics: (issue.executiveBriefingTopics ?? []).map(
              (topic) => ({
                ...topic,
                stories: (topic.stories ?? []).map((story) => {
                  if (!filter.ids) return story;
                  if (!filter.ids.includes(story.id)) return story;
                  return {
                    ...story,
                    ...set,
                  };
                }),
              })
            ),
          })),
        }));
      });
    },
    onSuccess: (stories, { filter }) => {
      queryClient.invalidateQueries({ queryKey: ["stories"] });
      queryClient.invalidateQueries({ queryKey: ["issues"] });
      stories.forEach((story) => {
        queryClient.setQueryData<Story>(
          ["story", filter, fragmentName],
          () => story
        );
      });
    },
  });

  const deleteStories = useMutation<void, Error, StoryFilter>({
    mutationFn: async (filter) => {
      if (!filter.ids) return;
      await client.request(
        gql`
          mutation DeleteStories($filter: StoryFilter!) {
            deleteStories(filter: $filter) {
              stories {
                id
              }
            }
          }
        `,
        { filter }
      );
    },
    onMutate: (filter) => {
      if (!filter.ids) return;

      filter.ids.forEach((id) => {
        queryClient.removeQueries({ queryKey: ["story", id] });
      });

      const issuesCache = queryClient.getQueriesData<IssueOutput>({
        queryKey: ["issues"],
      });

      issuesCache.forEach(([key, issueOutput]) => {
        if (!issueOutput) return;
        queryClient.setQueryData<IssueOutput>(key, () => ({
          ...issueOutput,
          issues: issueOutput.issues.map((issue) => ({
            ...issue,
            executiveBriefingTopics: (issue.executiveBriefingTopics ?? []).map(
              (topic) => ({
                ...topic,
                stories: (topic.stories ?? []).filter(
                  (story) =>
                    !filter.ids?.includes(story.id) &&
                    filter.issueId !== issue.id
                ),
              })
            ),
          })),
        }));
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["stories"] });
      queryClient.invalidateQueries({ queryKey: ["issues"] });
      queryClient.invalidateQueries({ queryKey: ["issue"] });
    },
  });

  return {
    query: useStoriesQuery,
    mutator,
    updater,
    deleter: deleteStories,
  };
};
