import { styled } from "@mui/material";
import tokens from "@tbml/tokens";
import { Link } from "../Link";

export const BreadcrumbsWrapper = styled("nav")`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const InvertedLink = styled(Link)`
  color: ${tokens.color.textSecondary.value};

  &:hover {
    color: ${tokens.color.textPrimary.value};
  }
`;
